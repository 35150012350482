import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

const Imprint = () => (
  <div className={classnames(styles.imprint, 'col-centered', '--narrow')}>
    <h2>Imprint</h2>
    <p>
      Shadow Esports GmbH
      <br />
      Kottbusser Straße 11
      <br />
      DE 10999 Berlin, Germany
      <br />
    </p>
    <p>Registered at District Court Berlin Charlottenburg, HRB 210718 B</p>
    <p>UID DE326870548</p>
    <h5>Managing Directors:</h5>
    <p>Benjamin Riewe</p>
    <h5>Editorial responsibility according to §55 RStV:</h5>
    <p>
      Benjamin Riewe
      <br />
      Kottbusser Straße 11
      <br />
      DE 10999 Berlin, Germany
    </p>
    <p>E-Mail: contact@shadow.gg</p>
  </div>
)

export default Imprint
